export default [
  {
    key: 'maxTotalBet',
    label: 'field.maxTotalBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'dropTotalPayout',
    label: 'field.dropTotalPayout',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutAmountPerBet',
    label: 'field.cutAmountPerBetRiel',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutPercentPerBet',
    label: 'field.cutPercentPerBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutMaxPayout',
    label: 'field.cutMaxPayout',
    rules: 'required|decimal:2',
    type: 'text',
  },
  {
    key: 'cutMinPayout',
    label: 'field.cutMinPayout',
    rules: 'required|decimal:2',
    type: 'text',
  },
  {
    key: 'cutType',
    label: 'field.cutType',
    rules: 'required',
    type: 'radio',
    options: [
      {
        value: 'bet',
        text: 'general.bet',
      },
      {
        value: 'game',
        text: 'general.game',
      },
    ],
  },
  {
    key: 'isCutPercent',
    label: 'field.isCutPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
  },
  {
    label: 'breadcrumb.channel1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxTotalBetBetween',
    label: 'field.maxTotalBetBetween',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxTotalDrawBetGame',
    label: 'field.maxTotalDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.channel2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxTotalBetBetweenTwo',
    label: 'field.maxTotalBetBetween',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxTotalDrawBetGameTwo',
    label: 'field.maxTotalDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxTotalBetBetweenBacar',
    label: 'field.maxTotalBetBetweenBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxTotalDrawBetGameBacar',
    label: 'field.maxTotalDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxTotalBetBetweenBacarTwo',
    label: 'field.maxTotalBetBetweenBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxTotalDrawBetGameBacarTwo',
    label: 'field.maxTotalDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.secondBetSetting',
    icon: 'SettingsIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutSecondPercentPerBet',
    label: 'field.cutSecondPercentPerBet',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutSecondMinPayout',
    label: 'field.cutSecondMinPayout',
    rules: 'decimal:2',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isCutSecondPercent',
    label: 'field.isCutSecondPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.externalAgent',
    icon: 'SettingsIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'maxTotalBetExt',
    label: 'field.maxTotalBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'dropTotalPayoutExt',
    label: 'field.dropTotalPayout',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutAmountPerBetExt',
    label: 'field.cutAmountPerBetRiel',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutPercentPerBetExt',
    label: 'field.cutPercentPerBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'cutMaxPayoutExt',
    label: 'field.cutMaxPayout',
    rules: 'required|decimal:2',
    type: 'text',
  },
  {
    key: 'cutMinPayoutExt',
    label: 'field.cutMinPayout',
    rules: 'required|decimal:2',
    type: 'text',
  },
  {
    key: 'cutTypeExt',
    label: 'field.cutType',
    rules: 'required',
    type: 'radio',
    options: [
      {
        value: 'bet',
        text: 'general.bet',
      },
      {
        value: 'game',
        text: 'general.game',
      },
    ],
  },
  {
    key: 'isCutPercentExt',
    label: 'field.isCutPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
  },
  {
    label: 'breadcrumb.secondBetSettingExternalAgent',
    icon: 'SettingsIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutSecondPercentPerBetExt',
    label: 'field.cutSecondPercentPerBet',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutSecondMinPayoutExt',
    label: 'field.cutSecondMinPayout',
    rules: 'decimal:2',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isCutSecondPercentExt',
    label: 'field.isCutSecondPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
    resource: 'bet-setting',
    action: 'update',
  },
]
