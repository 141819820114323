<template>
  <div>
    <load-profile />
    <b-card>
      <validation-observer
        ref="updateForm"
        #default="{ invalid }"
      >
        <n-form-confirmation
          key="updateForm"
          :form="$refs.updateForm"
          :disabled="invalid"
          @submit="submit"
        >
          <div>
            <h4 class="mb-2">
              {{ $t("field.exchangeReward") }}
            </h4>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :vid="`amount-per-point`"
                    :name="$t('field.amount')"
                    rules="required|integer"
                  >
                    <label>
                      {{ $t("field.amount") }} (៛/{{ $t("field.point") }})
                    </label>
                    <div>
                      <n-currency-input
                        v-model="data.amountPerPoint"
                        :name="`amount-per-point`"
                        :placeholder="$t('field.amount')"
                        :errors="errors"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-for="(item, idx) in data.list"
            :key="item.id"
          >
            <hr>
            <h4 class="mb-2">
              {{ item.channelTypeName }}
            </h4>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :vid="`amount-${item.id}`"
                    :name="$t('field.amount')"
                    rules="required|integer"
                  >
                    <label>
                      {{ $t("field.amount") }} (៛/{{ $t("field.point") }})
                    </label>
                    <div>
                      <n-currency-input
                        v-model="item.turnover"
                        :name="`amount-${item.id}`"
                        :placeholder="$t('field.amount')"
                        :errors="errors"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-checkbox
              v-model="item.isEnable"
              :name="`isEnable-${item.id}`"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('status.active') }}
            </b-form-checkbox>
          </div>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('update', 'bet-setting')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1"
                :loading="loading"
                :disabled="invalid"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormCheckbox, BFormGroup, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NCurrencyInput from '@/components/NCurrencyInput.vue'
import FormInput from './formInput'

const PointRuleRepository = Repository.get('pointRule')

export default {
  components: {
    BFormCheckbox,
    NCurrencyInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        amountPerPoint: null,
        list: [],
      },
      loading: false,
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      PointRuleRepository.index().then(response => {
        const { data } = response.data
        this.data = data
      })
    },
    submit() {
      this.$refs.updateForm.validate().then(success => {
        if (success) {
          this.loading = true
          PointRuleRepository.update(this.data)
            .then(() => {
              this.$swal({
                text: this.$t('alert.saveSuccess'),
                icon: 'success',
                confirmButtonText: this.$t('button.close'),
              })
            })
            .catch(error => {
              if (error.response?.data?.message) {
                this.$refs.updateForm.setErrors(error.response?.data?.message)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
  },
  setup() {
    const fields = FormInput.map(value => value)

    return { fields }
  },
}
</script>
